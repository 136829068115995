/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
  }
  .ripple:hover {
    background: transparent radial-gradient(circle, transparent 1%, rgba(202, 238, 247,0.25) 0%) center/15000%;
  }
  .ripple:active {
    background-color: #0F2557;
    background-size: 100%;
    transition: background 0s;
  }