/* fonts.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #FFFFFF;
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
